<template>
  <v-card
    elevation="2"
    class="fill-height d-flex flex-column"
    @keyup.esc="cancelRecordEdit()"
  >
    <v-card
      :color="$attrs['title-color']"
      class="white--text mt-n2"
      elevation="1"
      style="position: absolute; width: 95%; left: 2.5%"
    >
      <div class="pa-2 text-subtitle-2 text-center">
        {{ title }}
      </div>
    </v-card>
    <div class="flex-grow-1">
      <v-data-table
        class="pt-8"
        fixed-header
        :headers="headers"
        :items="records"
        hide-default-footer
        disable-pagination
        :loading="loading"
        loading-text="Carregando... Por favor, aguarde"
        no-data-text="Sem registros"
        @click:row="editRow($event)"
        calculate-widths
        :height="tableHeight"
      >
        <template #[`item.category`]="{ item }">
          <template v-if="edittingRecord.id != item.id">
            <v-chip
              small label dark :color="item.category.color"
              class="break-span ma-1 px-2 py-1 ml-2"
              :class="$utils.textColorForBackground(item.category.color) + '--text'"
              style="height: auto"
            >
              {{ item.category.name }}
            </v-chip>
          </template>
          <template v-else>
            <v-autocomplete
              ref="categoryAutocompleteEdit"
              v-model="edittingRecord.cash_flow_category_id"
              label="Conta *"
              no-data-text="Nenhuma conta cadastrada"
              :rules="[$rules.required]"
              :items="categories"
              item-text="name" item-value="id"
              v-bind="fieldsAttrs"
              :style="{
                'min-width': categoryWidth,
                'width': categoryWidth,
                'max-width': categoryWidth,
              }"
              @keyup.enter="saveRecord()"
              :tabindex="3001"
            />
          </template>
        </template>

        <template #[`item.day`]="{ item }">
          <span v-if="edittingRecord.id != item.id" class="mx-1">
            {{ item.day || "-" }}
          </span>
          <template v-else>
            <v-text-field
              v-model="edittingRecord.day"
              label="Dia"
              v-mask="'##'"
              @keyup.enter="saveRecord()"
              v-bind="fieldsAttrs"
              :style="{
                'width': dayWidth,
                'max-width': dayWidth,
              }"
              :tabindex="3002"
            />
          </template>
        </template>

        <template #[`item.identification`]="{ item }">
          <span v-if="edittingRecord.id != item.id" class="mx-1">
            {{ item.identification || "-" }}
          </span>
          <template v-else>
            <v-text-field
              v-model="edittingRecord.identification"
              label="Complemento"
              v-bind="fieldsAttrs"
              @keyup.enter="saveRecord()"
              :tabindex="3003"
            />
          </template>
        </template>

        <template #[`item.amount`]="{ item }">
          <span v-if="edittingRecord.id != item.id" class="ml-2 mr-1">
            R$ {{ $utils.formatMoney(item.amount) }}
          </span>
          <div v-else class="d-flex">
            <v-text-field
              class="flex-grow-1"
              ref="editAmount"
              v-model.lazy="editAmountText"
              v-money="$utils.moneyFormat"
              v-bind="fieldsAttrs"
              :rules="[$rules.required]"
              @keyup.enter="saveRecord()"
              style="min-width: 80px"
              :tabindex="3004"
            />
            <v-tooltip :open-delay="250" bottom>
              Deletar {{ aRecord }}
              <template #activator="{ on }">
                <v-btn
                  color="error"
                  icon v-on="on"
                  :disabled="loadingSave"
                  @click="deleteRecord()"
                  :tabindex="3006"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip :open-delay="250" bottom>
              Salvar
              <template #activator="{ on }">
              <v-btn
                color="success" icon v-on="on"
                :disabled="loading"
                :loading="loadingSave"
                @click="saveRecord()"
                :tabindex="3005"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              </template>
            </v-tooltip> 
          </div>
        </template>

      </v-data-table>
    </div>
    <div class="flex-shrink" v-if="!cash_flow.is_consolidator">
      <v-divider />
      <v-form class="d-flex my-1" @submit.prevent="createRecord()">
        <v-autocomplete
          ref="recordCategory"
          v-model="newRecord.cash_flow_category_id"
          label="Conta *"
          no-data-text="Nenhuma conta cadastrada"
          :rules="[$rules.required]"
          :items="categories"
          item-text="name"
          item-value="id"
          v-bind="fieldsAttrs"
          :style="{
            'max-width': this.categoryWidth,
          }"
          :tabindex="baseTabindex + 1"
        >
          <template #append-item>
            <div class="d-block px-2">
              <v-divider class="mb-2" />
              <v-btn color="primary" small text block @click="$emit('newCategory')">
                Nova conta
              </v-btn>
            </div>
          </template>
        </v-autocomplete>
        <v-text-field
          v-if="cash_flow.type == 'monthly_with_days'"
          v-model="newRecord.day"
          label="Dia"
          v-mask="'##'"
          v-bind="fieldsAttrs"
          style="width: 80px"
          :tabindex="baseTabindex + 2"
        />
        <v-text-field
          v-model="newRecord.identification"
          label="Complemento"
          v-bind="fieldsAttrs"
          :tabindex="baseTabindex + 3"
        />
        <v-text-field
          ref="recordAmount"
          v-model.lazy="newAmountText"
          v-money="$utils.moneyFormat"
          prefix="R$"
          :rules="[$rules.required]"
          v-bind="fieldsAttrs"
          :tabindex="baseTabindex + 4"
        />
        <v-btn
          color="success"
          outlined
          style="min-width: 40px; height: 40px"
          class="green lighten-4 mx-1 px-0"
          :disabled="loading"
          :loading="loadingSave"
          type="submit"
          :tabindex="baseTabindex + 5"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-form>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CashFlowRecordCard",

  attrs: {
    'title-color': { required: true, type: String },
  },

  props: {
    records: {required: true},
    total: {type: Number, required: true},
    loading: {required: true, type: Boolean},
    title: {required: true, type: String},
    type: {required: true, type: String},
    categories: {required: true, type: Array},
    cash_flow: {required: true, type: Object},
    day: {type: Number},
    month: {required: true, type: Number},
    year: {required: true, type: Number},
    recordsHeight: {required: true, type: Number},
  },

  data(){
    return {
      newRecord: {
        cash_flow_category_id: undefined,
        identification: "",
        amount: undefined,
        day: undefined,
      },
      edittingRecord: {},
      newAmountText: "",
      editAmountText: "",
      loadingSave: false,
    };
  },

  computed: {
    baseTabindex() {
      if (this.type == 'inlet') {
        return 1000;
      } else {
        return 2000;
      }
    },
    tableHeight(){
      let height = this.recordsHeight;
      height -= 32; // padding top
      height -= 49; // new record form
      return height + 'px';
    },
    categoryWidth(){
      return '160px';
    },
    dayWidth(){
      return '50px';
    },
    headers(){
      let headers = [
        { text: "Conta", value: "category", sort: (a, b) => a.name < b.name, width: this.categoryWidth},
      ];
      if (this.cash_flow.type === 'monthly_with_days'){
        headers.push({ text: "Dia", value: "day", width: this.dayWidth });
      }
      headers = headers.concat([
        { text: "Complemento", value: "identification", },
        { text: "Valor", value: "amount", width: '150px' },
      ]);
      return headers;
    },
    fieldsAttrs(){
      return {
        outlined: true,
        dense: true,
        'hide-details': true,
        disabled: this.loadingSave || this.loading,
        class: "ml-1",
      };
    },
    aRecord(){
      return this.title.slice(0, -1).toLowerCase();
    },
  },

  watch: {
    'newAmountText'(amount){
      try {
        this.newRecord.amount = this.$utils.formattedMoneyToDecimal(amount);
      } catch (e){
        this.newRecord.amount = undefined;
      }
    },
    'editAmountText'(amount){
      try {
        this.edittingRecord.amount = this.$utils.formattedMoneyToDecimal(amount);
      } catch (e){
        console.log(e);
        this.edittingRecord.amount = undefined;
      }
    },
  },

  methods: {
    onlyDigits(e){
      e = e || window.event;
      const charCode = e.which || e.keyCode;
      const char = String.fromCharCode(charCode);
      if (charCode !== 13){
        if ('.,'.includes(char)){
          if (e.target.value.includes(',') || e.target.value.includes(',')){
            // only one decimal separator
            e.preventDefault();
          }
        } else if (!(48 <= charCode && charCode <= 57)){
          // not a number
          e.preventDefault();
        }
      }
    },
    editRow(record){
      if (!this.cash_flow.is_consolidator){
        if (this.edittingRecord.id !== record.id){
          this.edittingRecord = Object.assign({}, record);
          this.editAmountText = this.$utils.formatMoney(record.amount);
        }
      }
    },
    cancelRecordEdit(){
      this.edittingRecord = {};
    },
    deleteRecord(){
      this.$confirmDialog(
        `Tem certeza que quer deletar esta ${this.aRecord}?`,
        'O registro será apagado permanentemente.',
        () => new Promise((resolve, reject) => {
          this.$axios
            .delete(`/cash-flow/record/${this.edittingRecord.id}`)
            .then(() => {
              this.$showMessage("success", "Registro deletado");
              resolve();
            })
            .catch(() => {
              this.$showMessage("error", "Erro inesperado ao deletar registro");
              reject();
            })
            .finally(() => {
              this.$emit("updated");
              this.loadingSave = false;
            });
        }),
      );
    },
    saveRecord(){
      this.loadingSave = true;
      this.edittingRecord.day = this.edittingRecord.day || undefined;
      this.$axios.put(`/cash-flow/record/${this.edittingRecord.id}`, this.edittingRecord)
        .then(() => {
          this.$emit("updated");
          this.edittingRecord = {};
        })
        .catch(() => {
          this.$showMessage("error", "Erro inesperado ao salvar registro");
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    createRecord(){
      if (!this.$refs.recordCategory.validate()) {
        this.$showMessage("warning", "Selecione a Conta");
      } else {
        this.loadingSave = true;
        this.newRecord.day = this.newRecord.day || undefined;
        if (this.cash_flow.type == 'daily'){
          this.newRecord.day = this.day;
        }
        this.$axios
          .post('/cash-flow/record', {
            ...this.newRecord,
            month: this.month,
            year: this.year,
            cash_flow_id: this.cash_flow.id,
            type: this.type,
          })
          .then(() => {
            this.newRecord = {
              cash_flow_category_id: undefined,
              identification: "",
              amount: 0,
            };
            this.$refs.recordCategory.reset();
            this.$refs.recordAmount.$el.querySelector('input').value = "";
          })
          .catch(() => {
            this.$showMessage("error", "Erro inesperado ao criar registro");
          })
          .finally(() => {
            this.$emit("updated");
            this.loadingSave = false;
          });
      }
    },
  },
};
</script>

<style scoped>
/deep/ th, /deep/ td {
  padding: 0 !important;
}
/deep/ tr > th:first-child {
  padding-left: 10px !important;
}
/deep/ tr > th:last-child {
  padding-right: 10px !important;
}
/deep/ .break-span span {
  white-space: normal;
  line-height: 15px;
  line-break: normal !important;
  height: auto;
}
</style>
