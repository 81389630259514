<template>
  <div class="fill-height d-flex flex-column pa-1">
    <div class="d-flex align-center py-0">
      <v-chip
        label
        class="text-h6 py-5 px-4 rounded-r-sm"
        @click="consolidator ? $refs.consolidatedDialog.open():null"
      >
        <template v-if="!!consolidator">
          {{ consolidator.name }}
          <v-icon size="24px" class="mx-1">mdi-chevron-right</v-icon>
          {{ cash_flow.id == consolidator.id ? "Consolidado" : cash_flow.name }}
        </template>
        <template v-else>
          {{ cash_flow.name }}
        </template>
      </v-chip>
      <v-divider vertical class="mx-0 grey ligthen-2" />
      <v-btn
        color="grey lighten-2"
        small depressed class="px-0 py-5 rounded-l-sm"
        width="0"
        elevation="0"
        @click="$refs.cashFlowDialogForm.open(cash_flow)"
        :disabled="loading"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>

      <v-btn
        class="ml-2 py-4 px-3"
        small text color="primary"
        @click="$refs.categoriesDialog.open()"
        :disabled="loading"
      >
        <v-icon left>mdi-shape</v-icon>
        <template v-if="$vuetify.breakpoint.lgAndUp">
          Gerenciar
        </template>
        Contas
      </v-btn>

      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn
            class="ml-2 py-4 px-3"
            small text color="primary"
            v-on="on"
            :disabled="loading"
          >
            <v-icon left>mdi-file-export</v-icon>
            Exportar
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            target="_blank"
            :href="`${$axios.defaults.baseURL}/cash-flow/${cash_flow.id}/report/${currentMonth}/${currentYear}`"
          >
            <v-list-item-subtitle class="font-weight-medium">
              Livro Caixa em PDF
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-menu>
      
      <v-btn class="ml-2 py-4 px-3"
      small text color="primary"
      @click="$refs.searchDialog.open(cash_flow_id)">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        v-for="(button, i) in buttonOptions" :key="i"
        class="ml-1 py-4"
        small depressed
        color="primary white--text"
        :disabled="!button.action || loading"
        @click="button.action()"
      >
        {{ button.title }}
      </v-btn>
    </div>

    <div class="flex-grow-1 pt-1 d-flex flex-column align-center">

      <v-sheet width="100%">
        <v-row no-gutters>
          <v-col cols="6">
            <v-select
              v-model="currentYear"
              :items="years"
              dense filled hide-details
              label="Ano"
              :disabled="loading"
            />
          </v-col>
          <v-divider vertical class="mx-1" />
          <v-col>
            <div class="d-flex">
              <v-select
                label="Saldo no início do ano"
                class="rounded-r-md rounded-l-0"
                v-model="previousBalance"
                :items="previousBalanceOptions"
                filled dense hide-details
                style="max-width: 300px"
                :disabled="loadingNewBalanceAmount || loading || (consolidator && cash_flow.id == consolidator.id)"
              />
              <template>
                <v-divider vertical />
                <v-form class="flex-grow-1 d-flex align-center" @submit.prevent="setNewPreviousBalance()">
                  <v-text-field
                    label="Saldo"
                    prefix="R$"
                    :class="{
                      'rounded-0': previousBalance == 'manual',
                      'rounded-r-md rounded-l-0': previousBalance == 'auto',
                    }"
                    filled dense hide-details
                    ref="previousBalanceAmount"
                    v-model.lazy="previousBalanceAmount"
                    v-money="$utils.moneyFormat"
                    :disabled="loadingNewBalanceAmount || previousBalance == 'auto' || loading"
                  />
                  <v-fade-transition>
                    <v-btn
                      v-if="previousBalance == 'manual'"
                      color="success lighten-1"
                      min-width="0"
                      height="100%"
                      class="px-2 rounded-r-md rounded-l-0"
                      elevation="1"
                      type="submit"
                      :loading="loadingNewBalanceAmount"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-fade-transition>
                </v-form>
              </template>
            </div>
          </v-col>
        </v-row>
        <!-- month -->
        <v-select
          v-model="currentMonth"
          :items="monthsMobile"
          filled label="Mês" hide-details
          v-if="$vuetify.breakpoint.mobile"
        />
        <div v-else>
          <v-divider class="mt-1" />
          <v-tabs
            v-model="currentMonth"
            slider-color="primary"
            icons-and-text
            fixed-tabs
            height="40px"
          >
            <v-tabs-slider color="primary" />
            <v-tab
              v-for="month in months" :key="month"
              class="d-flex flex-column align-center"
              active-class="grey lighten-2"
              style="min-width: 40px"
            >
              <div class="text-subtitle-2 my-0">
                {{ month }}
              </div>
            </v-tab>
          </v-tabs>
          <v-divider/>
        </div>

        <!-- /month -->
        <!-- day -->
        <template v-if="cash_flow.type == 'daily'">
          <v-select
            v-model="currentDay"
            :items="days"
            filled label="Dia" hide-details
            v-if="$vuetify.breakpoint.mobile"
          />
          <div v-else>
            <v-tabs
              v-model="currentDay"
              slider-color="primary"
              icons-and-text
              fixed-tabs
              height="40px"
            >
              <v-tabs-slider color="primary" />
              <v-tab
                v-for="day in days" :key="day.value"
                class="d-flex flex-column align-center"
                active-class="grey lighten-2"
                style="min-width: 8px; width: 8px"
              >
                <div class="text-subtitle-2 my-0">
                  {{ day.text }}
                </div>
              </v-tab>
            </v-tabs>
            <v-divider/>
          </div>
        </template>
        <!-- /day -->
      </v-sheet>

      <!-- RECORDS -->
      <v-sheet class="flex-grow-1 d-flex align-center pt-4" width="100%">

        <v-btn color="grey lighten-4" fab depressed small @click="changeMonth(-1)" style="z-index: 2">
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>

        <div class="flex-grow-1 fill-height">
          <transition :name="animation.currentTransition">
            <div
              class="d-flex flex-column fill-height"
              v-if="animation.showingContent"
            >
              <v-row no-gutters>
                <v-col cols="6">
                  <CashFlowRecordCard
                    ref="inlet"
                    :cash_flow="cash_flow"
                    :records="cash_flow.inlet"
                    :total="cash_flow.inlet_total"
                    :loading="loading || loadingCategories"
                    :categories="categories.inlet"
                    :year="currentYear"
                    :month="currentMonth"
                    :day="currentDay+1"
                    title="Entradas"
                    title-color="teal darken-3"
                    type="inlet"
                    class="mx-1"
                    @updated="loadCashFlow()"
                    @newCategory="$refs.newAndEditCategoryDialog.open()"
                    :recordsHeight="recordsHeight"
                  />
                </v-col>
                <v-col cols="6">
                  <CashFlowRecordCard
                    ref="outflow"
                    :cash_flow="cash_flow"
                    :records="cash_flow.outflow"
                    :total="cash_flow.outflow_total"
                    :loading="loading || loadingCategories"
                    :categories="categories.outflow"
                    :day="currentDay+1"
                    :year="currentYear"
                    :month="currentMonth"
                    title="Saídas"
                    title-color="deep-orange darken-3"
                    type="outflow"
                    class="mx-1"
                    @updated="loadCashFlow()"
                    @newCategory="$refs.newAndEditCategoryDialog.open()"
                    :recordsHeight="recordsHeight"
                  />
                </v-col>
              </v-row>
              <div class="mt-2 mb-1">
                <v-row no-gutters>
                  <v-col v-for="(card, i) in overviewCards" :key="i">
                    <v-card class="mx-1 d-flex align-center grey lighten-3" elevation="0">
                      <v-row align="center" no-gutters>
                        <v-col cols="5">
                          <v-card :color="card.color" class="py-2 px-1 rounded-r-sm text-center">
                            {{ card.title }}:
                          </v-card>
                        </v-col>
                        <v-col cols="7" class="px-2">
                          R$ {{ $utils.formatMoney(card.value) }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </div>
          </transition>
        </div>

        <v-btn color="grey lighten-4" fab depressed small @click="changeMonth(+1)" style="z-index: 2">
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>

      </v-sheet>

    </div>

    <NewAndEditCashFlowDialog
      ref="cashFlowDialogForm"
      @updated="loadCashFlow()"
    />

    <ConsolidatedDialog
      v-if="consolidator"
      ref="consolidatedDialog"
      :consolidator="consolidator"
      :loading="loading"
      @updated="loadCashFlowData()"
      @changeCashFlow="openConsolidatedCashFlow($event)"
    />

    <CategoriesDialog
      ref="categoriesDialog"
      :cash_flow_name="consolidator ? consolidator.name : cash_flow.name"
      @updated="loadCashFlow()"
    />

    <NewAndEditCategoryDialog
      ref="newAndEditCategoryDialog"
      @updated="loadCategories()"
    />

    <BalanceAnalysisDialog
      v-if="cash_flow.id != undefined"
      ref="balanceAnalysisDialog"
      :years="years"
      :cash_flow_id="cash_flow.id"
    />

    <CategoriesAnalysisDialog
      v-if="cash_flow.id != undefined"
      ref="categoriesAnalysisDialog"
      :years="years"
      :cash_flow_id="cash_flow.id"
    />

    <SearchDialog ref="searchDialog"/>
  </div>
</template>

<script>
import CashFlowRecordCard from "@/components/cash_flow/CashFlowRecordCard.vue";
import CategoriesDialog from "@/components/cash_flow/CategoriesDialog.vue";
import ConsolidatedDialog from "@/components/cash_flow/ConsolidatedDialog.vue";
import NewAndEditCashFlowDialog from "@/components/cash_flow/NewAndEditCashFlowDialog.vue";
import NewAndEditCategoryDialog from "@/components/cash_flow/NewAndEditCategoryDialog.vue";
import BalanceAnalysisDialog from "@/components/cash_flow/BalanceAnalysisDialog.vue";
import CategoriesAnalysisDialog from "@/components/cash_flow/CategoriesAnalysisDialog.vue";
import SearchDialog from "../../components/cash_flow/SearchDialog.vue";

export default {
  name: "CashFlow",

  components: {
    CashFlowRecordCard,
    CategoriesDialog,
    ConsolidatedDialog,
    NewAndEditCashFlowDialog,
    NewAndEditCategoryDialog,
    BalanceAnalysisDialog,
    CategoriesAnalysisDialog,
    SearchDialog
},

  props: ["contentHeight"],

  data: () => ({
    months: ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"],
    loading: false,
    loadingCategories: false,
    consolidator: undefined,
    cash_flow: {
      id: undefined,
      name: undefined,
      type: undefined,
      inlet: [],
      outflow: [],
      inlet_total: 0,
      outflow_total: 0,
      variation: 0,
      balance: 0,
    },
    loadingNewBalanceAmount: false,
    previousBalanceAmount: 0,
    previousBalance: '',
    previousBalanceOptions: [
      {value: 'auto', text: "Saldo do ano anterior"},
      {value: 'manual', text: "Definir manualmente"},
    ],
    categories: {
      inlet: [],
      outflow: [],
      both: [],
    },
    tabs: [],
    days: [],
    currentDay: new Date().getDate()-1,
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
    animation: {
      currentTransition: 'slide-y-transition',
      showingContent: false,
    },
    spaceBeforeContent: 139-40+8 + 40 + 8+8 + 40+8*2,
  }),

  computed: {
    recordsHeight(){
      let before = this.spaceBeforeContent;
      if (this.cash_flow.type == 'daily'){
        before += 40;
      }
      return Math.max(0, this.contentHeight - before);
    },
    buttonOptions(){
      return [
        {
          title: "Análise das Contas",
          action: () => this.$refs.categoriesAnalysisDialog.open(),
        },
        {
          title: "Fluxo do Caixa",
          action: () => this.$refs.balanceAnalysisDialog.open(),
        },
      ];
    },
    monthsMobile(){
      return [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
      ].map((m, i) => ({
        text: m,
        value: i,
      }))
    },
    years(){
      let years = [];
      for (let y = 2015; y <= this.currentYear + 2; ++y){
        years.unshift(y);
      }
      return years;
    },
    cash_flow_id() {
      return parseInt(this.$route.params.cash_flow_id);
    },
    overviewCards(){
      return [
        {
          title: "Entradas",
          color: "teal darken-3 white--text",
          value: this.cash_flow.inlet_total,
        },
        {
          title: "Saídas",
          color: "deep-orange darken-3 white--text",
          value: this.cash_flow.outflow_total,
        },
        {
          title: "Variação",
          color: "white--text darken-3 " + (this.cash_flow.variation >= 0 ? "green":"red"),
          value: this.cash_flow.variation,
        },
        {
          title: "Saldo",
          color: "white--text darken-3 " + (this.cash_flow.balance >= 0 ? "success":"error"),
          value: this.cash_flow.balance,
        },
      ];
    },
  },

  methods: {
    openConsolidatedCashFlow(cash_flow){
      this.cash_flow = {
        ...cash_flow,
        inlet: [],
        outflow: [],
        inlet_total: 0,
        outflow_total: 0,
        variation: 0,
        balance: 0,
      };
      this.loadCashFlow();
    },
    reload(){
      if (this.cash_flow.id == undefined){
        if (!this.loading) {
          this.loadCashFlow();
        }
      } else if (this.cash_flow.owner_id != this.$store.state.user.current_company.id) {
        this.$router.replace({name: 'cash-flows'});
      }
    },
    // calculateRecordsHeight(){
    //   this.recordsHeight = 140;
    //   this.$nextTick(() => {
    //     this.recordsHeight = this.$refs.recordsContent.clientHeight;
    //   });
    // },
    updateDays(){
      if (!['daily', undefined].includes(this.cash_flow.type))
        return [];
      let days = [];
      const daysInMonth = new Date(this.currentYear, this.currentMonth+1, 0).getDate();
      for (let day = 1; day <= daysInMonth; ++day){
        days.push({
          value: day-1,
          text: day,
        });
      }
      if (this.currentDay >= days.length){
        this.currentDay = days.length-1;
      }
      this.days = days;
    },
    changeMonth(delta){
      if (this.cash_flow.type == 'daily'){
        this.currentDay += delta;
        if (this.currentDay < 0){
          this.currentMonth--;
          this.currentDay = 31;
        } else if (this.currentDay >= this.days.length) {
          this.currentMonth++;
          this.currentDay = 0;
        }
      } else {
        this.currentMonth += delta
      }
      if (this.currentMonth < 0){
        this.currentYear--;
        this.currentMonth = 11;
      } else if (this.currentMonth > 11){
        this.currentYear++;
        this.currentMonth = 0;
      }
    },
    animateMonthChange(newMonth, previousMonth){
      this.animation.currentTransition = 'content-' + (newMonth < previousMonth ? 'left':'right');
      this.runAnimation();
    },
    animateYearChange(){
      this.animation.currentTransition = 'slide-y-transition';
      this.runAnimation();
    },
    runAnimation(){
      this.$nextTick(() => {
        this.animation.showingContent = false;
        this.cash_flow.inlet= [];
        this.cash_flow.outflow= [];
        this.cash_flow.inlet_total= 0;
        this.cash_flow.outflow_total= 0;
        this.cash_flow.variation= 0;
        this.cash_flow.balance= 0;
        setTimeout(() => {
          this.animation.showingContent = true;
          this.loadCashFlow();
        }, 250);
      });
    },
    async loadCashFlowData(){
      try {
        this.loading = true;
        const response = await this.$axios.get(`/cash-flow/${this.cash_flow_id}`);
        let cash_flow = response.data;
        if (cash_flow.is_consolidator){
          this.consolidator = cash_flow;
        }
        this.cash_flow = {
          ...cash_flow,
          inlet: [],
          outflow: [],
          inlet_total: 0,
          outflow_total: 0,
          variation: 0,
          balance: 0,
        };
        this.loading = false;
      } catch(e) {
        console.log(e);
        this.loading = false;
        this.$showMessage("error", "Erro inesperado ao carregar o controle de caixa");
        this.$router.replace({name: 'cash-flows'});
      }
    },
    async loadCashFlow() {
      if (this.cash_flow.id == undefined){
        await this.loadCashFlowData();
      }
      this.loading = true;
      const id = this.cash_flow.id;
      const month = this.currentMonth;
      const year = this.currentYear;
      let options = {};
      if (this.cash_flow.type == 'daily'){
        options['params'] = {
          day: this.currentDay+1,
        };
      }
      await this.loadCategories();
      try {
        const response = await this.$axios.get(`/cash-flow/${id}/${month}/${year}`, options);
        this.cash_flow = response.data;
        if (this.cash_flow.manual_balance == undefined){
          this.previousBalance = 'auto';
          this.previousBalanceAmount = this.$utils.formatMoney(this.cash_flow.previous_year_balance);
        } else {
          this.previousBalance = 'manual';
          this.previousBalanceAmount = this.$utils.formatMoney(this.cash_flow.manual_balance);
        }
        this.$refs.previousBalanceAmount.$el.querySelector('input').value = this.previousBalanceAmount;
        // add current cash flow name to submenu
        this.$emit('tabname', this.cash_flow.name);
      } catch (e) {
        console.log(e);
        this.$showMessage("error", "Erro inesperado ao carregar registros do controle de caixa");
      } finally {
        this.loading = false;
      }
    },
    updatePreviousBalance(balance){
      this.loadingNewBalanceAmount = true;
      const id = this.cash_flow.id;
      const year = this.currentYear;
      this.$axios.patch(`/cash-flow/${id}/${year}/balance`, {balance})
        .then(() => {
          this.loadCashFlow();
          this.$showMessage('success', "Saldo anterior atualizado");
        })
        .catch(() => {
          this.$showMessage('error', "Erro inesperado ao atualizar saldo anterior");
        })
        .finally(() => {
          this.loadingNewBalanceAmount = false;
        })
    },
    setNewPreviousBalance(){
      try {
        let balance = this.$utils.formattedMoneyToDecimal(this.previousBalanceAmount);
        this.updatePreviousBalance(balance);
      } catch {
        this.$showMessage('warning', "Número inválido");
      }
    },
    async loadCategories() {
      this.loadingCategories = true;
      const id = (this.consolidator) ? this.consolidator.id : this.cash_flow.id;
      try {
        const response = await this.$axios.get(`/cash-flow/${id}/categories/segmented`)
        this.categories = response.data;
      } catch (e) {
        this.$showMessage("error", "Erro ao carregar Contas");
        console.log(e);
      } finally {
        this.loadingCategories = false;
      }
    },
    deleteCashFlow(){
      this.$confirmDialog(
        'Você deseja deletar este Controle de Caixa?',
        'O controle será apagado permanentemente.',
        () => new Promise((resolve, reject) => {
          this.$axios.delete(`/cash-flow/${this.data_id}`)
            .then(() => {
              resolve();
              this.$showMessage("success", "Controle de Caixa excluido");
              this.$router.replace({ name: 'cash-flows' });
            })
            .catch((error) => {
              console.log(error);
              this.$showMessage("error", "Não foi possivel excluir o Controle de Caixa");
              reject();
            })
        }),
      )
    },
  },
  watch: {
    '$route.params.cash_flow_id'() {
      this.loadCashFlow();
      this.animateYearChange();
    },
    currentDay(current, previous){
      this.animateMonthChange(current, previous);
      this.updateDays();
    },
    currentMonth(current, previous){
      this.previousBalance = '';
      this.animateMonthChange(current, previous);
      this.updateDays();
    },
    currentYear(){
      this.previousBalance = '';
      this.animateYearChange();
    },
    previousBalance(current, previous){
      if (current == 'auto' && previous == 'manual'){
        this.updatePreviousBalance();
      }
    },
    // 'cash_flow.type'(){
    //   this.calculateRecordsHeight();
    // },
  },  
  mounted(){
    this.animation.showingContent = true;
    this.updateDays();
    this.reload();
    // this.calculateRecordsHeight();
  },
};
</script>

<style scoped>
.content-left-leave-active,
.content-left-enter-active,
.content-right-leave-active,
.content-right-enter-active {
  transition: all 200ms ease-in-out;
}
.content-left-leave-to,
.content-left-enter,
.content-right-leave-to,
.content-right-enter {
  opacity: 0;
}
.content-left-leave-to  { transform: translateX(+10%); }
.content-left-enter     { transform: translateX(-10%); }
.content-right-leave-to { transform: translateX(-10%); }
.content-right-enter    { transform: translateX(+10%); }
</style>
