<template>
  <v-dialog v-model="showing">
    <v-card :height="height" class="d-flex flex-column">
      <v-card-title class="text-h6 d-flex align-center grey lighten-2 py-4">
        Análise das Contas
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-2 flex-grow-1" style="overflow: auto">
        <div class="fill-height d-flex">
          <div
            class="d-flex flex-column align-center pr-1"
            style="
              width: 400px;
              min-width: 400px;
              max-width: 400px;
            "
          >
            <div class="d-flex flex-row align-stretch">
              <div>
                <div class="d-flex flex-row">
                  <v-select
                    filled dense hide-details
                    label="Mês Inicial"
                    class="pa-1"
                    v-model="currentInitMonth"
                    :items="months"
                    min-width="0"
                  />
                  <v-select
                    filled dense hide-details
                    label="Ano Inicial"
                    class="pa-1"
                    v-model="currentInitYear"
                    :items="localYears"
                  />
                </div>
                <div class="d-flex flex-row">
                  <v-select
                    filled dense hide-details
                    label="Mês Final"
                    class="pa-1"
                    v-model="currentFinalMonth"
                    :items="months"
                  />
                  <v-select
                    filled dense hide-details
                    label="Ano Final"
                    class="pa-1"
                    v-model="currentFinalYear"
                    :items="localYears"
                  />
                </div>
              </div>
              <v-divider vertical class="mx-2" />
              <div class="d-flex flex-column justify-center">
                <v-btn
                  class="rounded-circle"
                  width="45"
                  height="45"
                  min-width="0"
                  min-height="0"
                  style="margin-bottom: 3px;"
                  color="primary"
                  depressed
                  @click="reload"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <v-divider class="mt-4 mb-1" />
            <v-progress-circular
              v-if="newLoading"
              indeterminate color="primary"
              :size="40" :width="2"
            />
            <CategoriesList
              v-else
              class="flex-grow-1 pr-1"
              :inlet="categories.inlet || []"
              :outflow="categories.outflow || []"
              :inlet_total="inlet_total"
              :outflow_total="outflow_total"
              selectable
              @setValue="setValue($event)"
            >
              <template #append="{ category }">
                <v-chip
                  v-if="category.amount !== undefined"
                  small label color="rgba(0,0,0,0.2)"
                  class="px-2 ml-1"
                >
                  R$ {{ $utils.formatMoney(category.amount) }}
                </v-chip>
              </template>
            </CategoriesList>
          </div>
          <div
            class="flex-grow-1 d-flex align-center"
            style="overflow: auto"
          >
            <v-progress-circular
              v-if="loading"
              indeterminate color="primary"
              :size="60" :width="2"
            />
            <vue-plotly
              v-else
              :data="data"
              :layout="layout"
              :displayModeBar="true"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePlotly from '@statnett/vue-plotly';
import CategoriesList from './CategoriesList.vue';

const months = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];

export default {
  name: "CategoriesAnalysisDialog",

  components: { VuePlotly, CategoriesList, },

  props: {
    years: {required: true, type: Array},
    cash_flow_id: {required: true, type: Number},
  },

  data() {
    return {
      localYears: this.years,
      showing: false,
      loading: false,
      newLoading: false,
      currentInitYear: null,
      currentFinalYear: null,
      currentInitMonth: null,
      currentFinalMonth: null,
      inlet_total: 0,
      outflow_total: 0,
      data: [],
      categories: {},
      selectedCategories: new Set(),
      months: months,
      graphWidth: 900,
      changeTimeout: undefined,
    };
  },

  computed: {
    height(){
      return (this.$vuetify.breakpoint.height * 0.9) + 'px';
    },
    layout(){
      return {
        title: `Evolução das Contas de ${this.currentInitMonth}/${this.currentInitYear} a ${this.currentFinalMonth}/${this.currentFinalYear}`,
        margin: {
          autoexpand: true,
          b: 100,
          // r: 10,
          t: 25,
          l: 110,
        },
        xaxis: {
        //   tickangle: -45
          showgrid: true,
        },
        yaxis: {
          tickformat: ',.2f',
        },
        separators: ',.',
        height: this.height-16-68,
        width: this.graphWidth,
        barmode: 'stack',
        legend: {
          orientation: 'v',
          x: 1,
        },
      };
    },
  },

  methods: {
    reload(){
      this.newLoading = true;
      this.resetLoader();
    },
    setValue(item){
      if (item.selected == true){
        this.selectedCategories.add(item.id);
        // let has = false;
        // this.selectedCategories.forEach((id) => {
        //   if (id === item.id){
        //     has = true;
        //   }
        // });
        // if (!has)
        //   this.selectedCategories.push(item.id);
      } else {
        // let id_list = [];  //Filter dava erro, split também
        // this.selectedCategories.forEach((id) => {
        //   if (id !== item.id){
        //     id_list.push(id);
        //   }
        // });
        // this.selectedCategories = id_list;
        this.selectedCategories.delete(item.id);
      }
      this.resetLoader();
    },
    loadYears(){
      this.loading = true;
      this.$axios.get(`/cash_flow/${this.cash_flow_id}/available-period`)
      .then((response) => {
        const data = response.data;
        this.currentInitMonth = months[data.initial_month];
        this.currentInitYear = data.initial_year;
        this.currentFinalMonth = months[data.final_month];
        this.currentFinalYear = data.final_year;
        this.localYears = [
          ...Array(data.final_year - data.initial_year + 1).keys()
        ].map((i) => data.initial_year + i);
        this.loadChart();
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    open(){
      this.showing = true;
      this.newLoading = true;
      this.loadYears();
    },
    loadChart(){
      this.loading = true;
      let categories = []
      // if (this.newLoading == false){
      //    categories = this.selectedCategories;
      // }
      this.$axios.get(
        `/cash_flow/${this.cash_flow_id}/categories-evolution/${this.currentInitYear}/${this.months.indexOf(this.currentInitMonth)}/${this.currentFinalYear}/${this.months.indexOf(this.currentFinalMonth)}`,
        {
          params: {
            categories: Array.from(this.selectedCategories).join(',')
          },
        },
      )
      .then((response) => {
        this.categories = response.data.totals;
        let categories = response.data.categories;
        let data = [];
        let balance = response.data.balance;
        this.inlet_total = response.data.inlet_total;
        this.outflow_total = response.data.outflow_total;
        const months_list = response.data.months_list;
        let newGraphWidth = months_list.length * 70;
        if (newGraphWidth > 900 && categories.length > 0){
          this.graphWidth = newGraphWidth;
        } else {
          this.graphWidth = 900;
        }
        if (categories.length === 0){
          this.data = [];
          return;
        }
        let firstRecordTypeText = null;
        [
          ['inlet', 'Entradas'],
          ['outflow', 'Saídas']
        ].forEach(([recordType, recordTypeText]) => {
          categories
          .filter(c => c.type == recordType)
          .forEach((category) => {
            const amounts = category[recordType];
            if (amounts){
              data.push(
                {
                  name: category.name,
                  type: 'bar',
                  marker: {
                    color: category.color,
                  },
                  x: [months_list, amounts.map(() => recordTypeText)],
                  y: amounts,
                },
              );
              if (!firstRecordTypeText) {
                firstRecordTypeText = recordTypeText;
              }
            }
          });
        });
        data.push({
          name: 'Balanço',
          type: 'line',
          fill: 'tonexty',
          fillcolor: 'rgba(76, 175, 80, 0.15)',
          marker: {
            color: '#212121',
          },
          x: [
            months_list,
            balance.map(() => firstRecordTypeText),
          ],
          y: balance.map((balance) => balance.balance),
        });
        this.data = data;
      })
      .finally(() => {
        this.loading = false;
        this.newLoading = false;
      })
    },
    resetLoader(){
      if (this.changeTimeout !== undefined){
        clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = setTimeout(
        () => this.loadChart(),
        1000,
      );
    },

    cleanValues() {
      this.selectedCategories = new Set();
      this.currentInitYear= null;
      this.currentFinalYear= null;
      this.currentInitMonth= null;
      this.currentFinalMonth= null;
      this.data = [];
      this.graphWidth = 900;
      this.categories = {};
      this.inlet_total = 0;
      this.outflow_total = 0;
    },

    close(){
      this.showing = false;
      this.cleanValues();
    },
  },

  destroyed() {
    this.cleanValues();
  },

  beforeDestroy() {
    this.cleanValues();
  },

  watch: {
    inlet_ids(){
      if (this.showing && !this.loading){
        this.resetLoader();
      }
    },
    outflow_ids(){
      if (this.showing && !this.loading){
        this.resetLoader();
      }
    },
  },

}
</script>

<style scoped>
/deep/ .modebar{
      display: none !important;
}
</style>
