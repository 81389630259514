<template>
  <v-dialog
    v-model="categoryDialog"
    max-width="800px"
  >
    <v-card>
      <v-toolbar flat color="grey lighten-2">
        <v-toolbar-title>
          Contas
        </v-toolbar-title>
        <v-toolbar-items class="flex-grow-1 d-flex align-center">
          <div class="flex-grow-1">
            <v-icon right>mdi-chevron-right</v-icon>
            <v-chip color="white" small label class="ml-2 px-2">
              {{ cash_flow_name }}
            </v-chip>
          </div>
          <div>
            <v-btn
              color="primary" depressed
              class="mr-4"
              @click="$refs.newAndEditCategoryDialog.open()"
            >
              Nova Conta
            </v-btn>
          </div>
          <v-btn icon @click="categoryDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-data-table
        :loading="loadingCategories"
        loading-text="Carregando... Por favor, aguarde"
        no-data-text="Nenhuma conta cadastrada"
        no-results-text="Nenhuma conta encontrada"
        :items="categories"
        :headers="headers"
        disable-pagination
        hide-default-footer
        fixed-header
        height="600px"
      >
        <template #[`header.actions`]>
        </template>

        <template #[`item.parent.name`]="{ item }">
          {{ item.parent ? item.parent.name : "-" }}
        </template>

        <template #[`item.type`]="{ item }">
          {{ type2ptbr(item.type) }}
        </template>

        <template #[`item.color`]="{ item }">
          <v-chip label class="px-4" :style="{ backgroundColor: item.color }" />
        </template>

        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn
                color="blue"
                class="ml-2"
                icon
                v-on="on"
                @click="$refs.newAndEditCategoryDialog.open(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar Conta</span>
          </v-tooltip>
          <template v-if="!item.have_children">
            <v-tooltip bottom :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="ml-2"
                  v-on="on"
                  @click="deleteCategory(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Deletar Conta</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
    </v-card>

    <NewAndEditCategoryDialog
      ref="newAndEditCategoryDialog"
      @updated="
        loadCategories();
        $emit('updated');
      "
    />
  </v-dialog>
</template>

<script>
import NewAndEditCategoryDialog from "@/components/cash_flow/NewAndEditCategoryDialog.vue";

export default {
  name: "CategoriesDialog",
  components: { NewAndEditCategoryDialog, },

  props: ['cash_flow_name'],

  data: () => ({
    categoryDialog: false,
    categories: [],
    loadingCategories: false,
    headers: [
      { text: "Conta", value: "name" },
      { text: "Tipo", value: "type" },
      { text: "Cor", value: "color", sortable: false},
      { text: "Grupo", value: "parent.name"},
      { text: "", value: "actions", sortable: false, align: "right"},
    ],
  }),

  computed: {
    cash_flow_id(){
      return this.$route.params.cash_flow_id;
    },
  },

  methods: {
    open() {
      this.categoryDialog = true;
      this.loadCategories();
    },
    type2ptbr(categoryType){
      switch (categoryType){
        case 'inlet': return "entrada";
        case 'outflow': return "saída";
        default: return "ambos";
      };
    },
    loadCategories() {
      this.loadingCategories = true;
      this.$axios.get(`/cash-flow/${this.cash_flow_id}/categories`)
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => this.$showMessage("error", "Erro inesperado ao carregar as contas"))
        .finally(() =>  this.loadingCategories = false);
    },
    deleteCategory(category){
      this.$confirmDialog(
        'Você deseja deletar essa Conta?',
        'A Conta será apagada permanentemente.',
        () => new Promise((resolve, reject) => {
          this.$axios.delete(`/cash-flow/category/${category.id}`)
          .then(() => {
            resolve();
            this.$showMessage("success", "Conta excluida");
            this.loadCategories();
          })
          .catch((error) => {
            reject(); 
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.$showMessage("warning", "Essa conta possui outras em seu grupo");
                  return;
              }
            }
            this.$showMessage("error", "Erro inesperado ao excluir");
          })
        }),
      )
    },
  },
};
</script>
