<template>
  <v-dialog
    v-model="showing"
    max-width="800px"
  >
    <v-card>
      <v-toolbar flat color="grey lighten-2">
        <v-toolbar-title>
          Controles Consolidados
        </v-toolbar-title>
        <v-toolbar-items class="flex-grow-1 d-flex align-center">
          <div class="flex-grow-1">
            <v-icon right>mdi-chevron-right</v-icon>
            <v-chip color="white" small label class="ml-2 px-2">
              {{ consolidator.name }}
            </v-chip>
          </div>
          <div>
            <v-btn
              color="primary" depressed
              class="mr-4"
              @click="$refs.newAndEditCashFlowDialog.open()"
            >
              Novo Controle
            </v-btn>
          </div>
          <v-btn icon @click="showing = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-data-table
        :loading="loading"
        loading-text="Carregando... Por favor, aguarde"
        no-data-text="Nenhum controle cadastrado"
        no-results-text="Nenhum controle encontrado"
        :items="cashFlows"
        :headers="headers"
        disable-pagination
        hide-default-footer
        fixed-header
        height="600px"
      >
        <template #[`header.actions`]>
        </template>

        <template #[`item.actions`]="{ item }">
          <div>
            <template v-if="item.id != consolidator.id">
              <v-tooltip bottom :open-delay="500">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="blue"
                    class="ml-2"
                    icon
                    v-on="on"
                    @click="$refs.newAndEditCashFlowDialog.open(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar Controle</span>
              </v-tooltip>
              <v-tooltip bottom :open-delay="500">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    color="error"
                    class="ml-2"
                    v-on="on"
                    @click="deleteCashFlow(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Deletar Controle</span>
              </v-tooltip>
            </template>
            <v-btn
              color="primary"
              class="ml-2"
              x-small depressed
              @click="
                showing = false;
                $emit('changeCashFlow', item);
              "
            >
              Acessar
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <NewAndEditCashFlowDialog
      ref="newAndEditCashFlowDialog"
      :consolidator_id="consolidator.id"
      @updated="$emit('updated')"
    />
  </v-dialog>
</template>

<script>
import NewAndEditCashFlowDialog from "@/components/cash_flow/NewAndEditCashFlowDialog.vue";

export default {
  name: "ConsolidatedDialog",
  components: { NewAndEditCashFlowDialog, },

  props: {
    consolidator: {required: true, type: Object},
    loading: {type: Boolean},
  },

  data: () => ({
    showing: false,
    headers: [
      { text: "Nome", value: "name" },
      { text: "", value: "actions", sortable: false, align: "right"},
    ],
  }),

  computed: {
    cashFlows(){
      return [
        {
          ...this.consolidator,
          name: "Consolidado",
        },
        ...this.consolidator.consolidated
      ];
    },
  },

  methods: {
    open() {
      this.showing = true;
    },
    deleteCashFlow(cashFlow) {
      this.$confirmDialog(
        "Você deseja deletar esse Controle de Caixa?",
        "O controle de caixa será apagado permanentemente.",
        () =>
          new Promise((resolve, reject) => {
            this.$axios
              .delete(`/cash-flow/${cashFlow.id}`)
              .then(() => {
                resolve();
                this.$showMessage("success", "Controle de caixa excluido");
                this.$emit('updated');
              })
              .catch((error) => {
                console.log(error);
                reject();
                this.$showMessage("error", "Erro inesperado ao excluir");
              });
          })
      );
    },
  },
};
</script>
