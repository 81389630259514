var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.showing),callback:function ($$v) {_vm.showing=$$v},expression:"showing"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" Controles Consolidados ")]),_c('v-toolbar-items',{staticClass:"flex-grow-1 d-flex align-center"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")]),_c('v-chip',{staticClass:"ml-2 px-2",attrs:{"color":"white","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.consolidator.name)+" ")])],1),_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.$refs.newAndEditCashFlowDialog.open()}}},[_vm._v(" Novo Controle ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showing = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Carregando... Por favor, aguarde","no-data-text":"Nenhum controle cadastrado","no-results-text":"Nenhum controle encontrado","items":_vm.cashFlows,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"600px"},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return undefined},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.id != _vm.consolidator.id)?[_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"blue","icon":""},on:{"click":function($event){return _vm.$refs.newAndEditCashFlowDialog.open(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Controle")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteCashFlow(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar Controle")])])]:_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","x-small":"","depressed":""},on:{"click":function($event){_vm.showing = false;
              _vm.$emit('changeCashFlow', item);}}},[_vm._v(" Acessar ")])],2)]}}],null,true)})],1),_c('NewAndEditCashFlowDialog',{ref:"newAndEditCashFlowDialog",attrs:{"consolidator_id":_vm.consolidator.id},on:{"updated":function($event){return _vm.$emit('updated')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }