<template>
  <v-dialog v-model="showing" width="auto">
    <v-card :height="height" class="d-flex flex-column">
      <v-card-title class="text-h6 d-flex align-center grey lighten-2 py-4">
        Fluxo do Caixa
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-2 flex-grow-1" style="overflow: auto;">
        <div class="fill-height d-flex">
          <div
            class="d-flex flex-column pr-1"
            style="
              width: 400px;
              min-width: 400px;
              max-width: 400px;
            "
          >
            <div class="d-flex flex-row align-stretch">
              <div>
                <div class="d-flex flex-row">
                  <v-select
                    filled dense hide-details
                    label="Mês Inicial"
                    class="pa-1"
                    v-model="currentInitMonth"
                    :items="months"
                    min-width="0"
                  />
                  <v-select
                    filled dense hide-details
                    label="Ano Inicial"
                    class="pa-1"
                    v-model="currentInitYear"
                    :items="localYears"
                  />
                </div>
                <div class="d-flex flex-row">
                  <v-select
                    filled dense hide-details
                    label="Mês Final"
                    class="pa-1"
                    v-model="currentFinalMonth"
                    :items="months"
                  />
                  <v-select
                    filled dense hide-details
                    label="Ano Final"
                    class="pa-1"
                    v-model="currentFinalYear"
                    :items="localYears"
                  />
                </div>
              </div>
              <v-divider vertical class="mx-2" />
              <div class="d-flex flex-column justify-center">
                <v-btn
                  class="rounded-circle"
                  width="45"
                  height="45"
                  min-width="0"
                  min-height="0"
                  style="margin-bottom: 3px;"
                  color="primary"
                  depressed
                  @click="reload"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <v-divider class="mt-4 mb-1" />
            <CategoriesList
              class="flex-grow-1 pr-1"
              :inlet="inlet_categories"
              :outflow="outflow_categories"
              :inlet_total="inlet_total"
              :outflow_total="outflow_total"
            >
              <template #append="{ category }">
                <template v-for="(content, i) in [
                  `R$ ${$utils.formatMoney(category.amount)}`,
                  `${category.percentage.toFixed(0)}%`,
                ]">
                  <template v-if="!['inlet','outflow'].includes(category.id) || content != '100%'">
                    <v-chip :key="i" small label color="rgba(0,0,0,0.2)" class="px-2 ml-1">
                      {{ content }}
                    </v-chip>
                  </template>
                </template>
              </template>
            </CategoriesList>
          </div>
          <div
            class="flex-grow-1 d-flex align-center"
            style="overflow: auto;"
          >
            <v-progress-circular
              v-if="loading"
              indeterminate color="primary"
              :size="60" :width="2"
            />
            <div
              v-else
              style="display: flex; overflow: auto;"
            >
              <vue-plotly
                :data="data"
                :layout="layout"
                :displayModeBar="true"
              />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePlotly from '@statnett/vue-plotly';
import CategoriesList from './CategoriesList.vue';

const months = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];

export default {
  name: "BalanceAnalysisDialog",

  components: { VuePlotly, CategoriesList, },

  props: {
    years: {required: true, type: Array},
    cash_flow_id: {required: true, type: Number},
  },

  data() {
    return {
      localYears: this.years,
      showing: false,
      loading: false,
      currentInitYear: null,
      currentFinalYear: null,
      currentInitMonth: null,
      currentFinalMonth: null,
      data: [],
      inlet_categories: [],
      outflow_categories: [],
      inlet_total: 0,
      outflow_total: 0,
      months: months,
      graphWidth: 900,
    };
  },

  computed: {
    height(){
      return (this.$vuetify.breakpoint.height * 0.9) + 'px';
    },
    layout(){
      return {
        title: `Evolução do Caixa de ${this.currentInitMonth}/${this.currentInitYear} a ${this.currentFinalMonth}/${this.currentFinalYear}`,
        margin: {
          autoexpand: true,
          b: 100,
          // r: 10,
          t: 25,
          l: 110,
        },
        yaxis: {
          tickformat: ',.2f',
        },
        separators: ',.',
        height: this.height-16-68,
        width: this.graphWidth,
      };
    },
  },

  methods: {
    reload(){
      this.loadChart();
    },

    open(){
      this.showing = true;
      this.loadYears();
    },

    loadYears(){
      this.loading = true;
      this.$axios.get(`/cash_flow/${this.cash_flow_id}/available-period`)
      .then((response) => {
        const data = response.data;
        this.currentInitMonth = months[data.initial_month];
        this.currentInitYear = data.initial_year;
        this.currentFinalMonth = months[data.final_month];
        this.currentFinalYear = data.final_year;
        this.localYears = [
          ...Array(data.final_year - data.initial_year + 1).keys()
        ].map((i) => data.initial_year + i);
        this.loadChart();
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },

    loadChart(){
      this.loading = true;
      this.$axios.get(`/cash_flow/${this.cash_flow_id}/year-balance/${this.currentInitYear}/${this.months.indexOf(this.currentInitMonth)}/${this.currentFinalYear}/${this.months.indexOf(this.currentFinalMonth)}`)
      .then((response) => {
        let evolution = response.data.months;
        const months_list = response.data.months_list;
        this.inlet_categories = response.data.inlet_categories;
        this.outflow_categories = response.data.outflow_categories;
        this.inlet_total = response.data.inlet_total;
        this.outflow_total = response.data.outflow_total;
        let newGraphWidth = months_list.length * 60;
        if (newGraphWidth > 900){
          this.graphWidth = newGraphWidth;
        } else {
          this.graphWidth = 900;
        }
        this.data = [
          {
            name: "Entrada",
            type: 'bar',
            marker: {
              color: '#009688',
            },
            x: months_list,
            y: evolution.map((evolution) => evolution.inlet),
          },
          {
            name: "Saída",
            type: 'bar',
            marker: {
              color: '#FF5722',
            },
            x: months_list,
            y: evolution.map((evolution) => evolution.outflow),
          },
          {
            name: "Caixa",
            type: 'line',
            fill: 'tonexty',
            fillcolor: 'rgba(76, 175, 80, 0.15)',
            marker: {
              color: '#212121',
            },
            x: months_list,
            y: evolution.map((evolution) => evolution.balance),
          },
        ];
      })
      .finally(() => {
        this.loading = false;
      })
    },


    cleanValues() {
      this.currentInitYear= null;
      this.currentFinalYear= null;
      this.currentInitMonth= null;
      this.currentFinalMonth= null;
      this.data = [];
      this.graphWidth = 900;
      this.inlet_categories = [];
      this.outflow_categories = [];
      this.inlet_total = 0;
      this.outflow_total = 0;
    },

    close(){
      this.showing = false;
      this.cleanValues();
    },

  },
}
</script>

<style scoped>
/deep/ .modebar{
      display: none !important;
}
</style>
