var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"contentDiv",staticClass:"pb-1",style:({
    overflow: 'auto',
    height: _vm.height || 'auto',
  })},_vm._l((_vm.data),function(categoryList,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex mt-4"},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(categoryList.title)+" ")]),_c('v-spacer'),(categoryList.total !== undefined)?_c('v-chip',{staticClass:"px-2",attrs:{"small":"","label":""}},[_vm._v(" R$ "+_vm._s(_vm.$utils.formatMoney(categoryList.total))+" ")]):_vm._e()],1),_c('v-treeview',{staticClass:"mt-2",attrs:{"value":_vm.$props[((categoryList.type) + "_ids")],"selected-color":"primary","items":_vm.trees[categoryList.type],"dense":"","hoverable":"","transition":"","disable-per-node":"","selectable":false,"height":_vm.height},scopedSlots:_vm._u([{key:"label",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center mb-1"},[(_vm.selectable)?_c('v-checkbox',{staticClass:"my-0 ml-5",class:_vm.$utils.textColorForBackground(item.color) + '--text',attrs:{"height":"2px","dense":"","hide-details":"","color":item.color},on:{"change":function () {
              _vm.$emit('setValue', item);
            }},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}}):_vm._e(),_c('v-card',{staticClass:"py-1 pl-2 pr-1 flex-grow-1 d-flex align-center",class:_vm.$utils.textColorForBackground(item.color) + '--text',attrs:{"label":"","dark":"","color":item.color,"elevation":0}},[_c('div',{staticClass:"mr-1 flex-grow-1",staticStyle:{"overflow-wrap":"break-word","word-wrap":"break-word","word-break":"break-all"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"d-flex"},[_vm._t("append",null,{"category":item})],2)])],1)]}}],null,true)})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }