var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.searchDialog),callback:function ($$v) {_vm.searchDialog=$$v},expression:"searchDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-2","height":"56px"}},[_c('v-toolbar-title',[_vm._v(" Pesquisa de Contas por Complemento ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.searchDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('div',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"filled":"","dense":"","label":"Pesquisar","autofocus":"","single-line":"","clearable":"","hide-details":""},on:{"click:append-outer":_vm.searchRecords,"click:clear":_vm.cleanValues,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchRecords.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loadingRecords,"loading-text":"Carregando... Por favor, aguarde","no-data-text":_vm.search.length > 1
          ? 'Nenhum registro encontrado'
          : 'Digite o complemento que deseja encontrar',"items":_vm.records,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":_vm.$vuetify.breakpoint.height * 0.9 - 56 * 3,"item-class":'v-data-table-inlet'},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s('' + (item.day ? ("" + (item.day < 9 ? '0':'') + (item.day) + "/"):'') + (("" + (item.month < 9 ? '0':'') + (item.month + 1))) + "/" + (item.year))+" ")]}},{key:"item.category",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"break-span ma-1 px-2 py-1 ml-2",class:_vm.$utils.textColorForBackground(item.category.color) + '--text',staticStyle:{"height":"auto"},attrs:{"small":"","label":"","dark":"","color":item.category.color}},[_vm._v(" "+_vm._s(item.category.name)+" ")])]}},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"py-2 px-1 rounded-r-sm text-center text--darken-2",class:{
            'error--text': item.type === 'outflow',
            'success--text': item.type === 'inlet',
          }},[_vm._v(" "+_vm._s(item.type === 'outflow'? 'Saída' : 'Entrada')+" ")])]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(" R$ "+_vm._s(_vm.$utils.formatMoney( item.amount))+" ")])]}}],null,true)}),_c('div',{staticClass:"pa-2 d-flex grey lighten-2",staticStyle:{"height":"56px"}},[(_vm.records.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"mx-1 d-flex align-center grey lighten-3",attrs:{"elevation":"0"}},[_c('v-row',{staticStyle:{"width":"50%"},attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"py-2 px-1 rounded-r-sm text-center",attrs:{"color":"teal darken-3 white--text"}},[_vm._v(" Entradas: ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"7"}},[_vm._v(" R$ "+_vm._s(_vm.$utils.formatMoney(_vm.totalInlet))+" ")])],1),_c('v-row',{staticStyle:{"width":"50%"},attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"py-2 px-1 rounded-r-sm text-center",attrs:{"color":"deep-orange darken-3 white--text"}},[_vm._v(" Saídas: ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"7"}},[_vm._v(" R$ "+_vm._s(_vm.$utils.formatMoney(_vm.totalOutflow))+" ")])],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }