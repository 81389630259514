var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.categoryDialog),callback:function ($$v) {_vm.categoryDialog=$$v},expression:"categoryDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" Contas ")]),_c('v-toolbar-items',{staticClass:"flex-grow-1 d-flex align-center"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")]),_c('v-chip',{staticClass:"ml-2 px-2",attrs:{"color":"white","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.cash_flow_name)+" ")])],1),_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.$refs.newAndEditCategoryDialog.open()}}},[_vm._v(" Nova Conta ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.categoryDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loadingCategories,"loading-text":"Carregando... Por favor, aguarde","no-data-text":"Nenhuma conta cadastrada","no-results-text":"Nenhuma conta encontrada","items":_vm.categories,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"600px"},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return undefined},proxy:true},{key:"item.parent.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.parent ? item.parent.name : "-")+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.type2ptbr(item.type))+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-4",style:({ backgroundColor: item.color }),attrs:{"label":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"blue","icon":""},on:{"click":function($event){return _vm.$refs.newAndEditCategoryDialog.open(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Conta")])]),(!item.have_children)?[_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteCategory(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar Conta")])])]:_vm._e()]}}],null,true)})],1),_c('NewAndEditCategoryDialog',{ref:"newAndEditCategoryDialog",on:{"updated":function($event){_vm.loadCategories();
      _vm.$emit('updated');}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }