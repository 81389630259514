<template>
  <v-dialog v-model="newAndEditCategoryDialog" max-width="500px" persistent>
    <v-card>
      <v-toolbar flat dark color="primary" max-height="65px">
        <v-toolbar-title>
          {{ isEditing ? "Edição de Conta" : "Nova Conta" }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-2">
        <v-form ref="categoryForm" class="pt-4" @submit.prevent="save()">
          <v-row no-gutters>
            <v-col cols="6">
              <v-text-field
                v-model="categoryData.name"
                label="Nome da Conta *"
                filled
                hide-details="auto"
                class="white white--text mr-1"
                color="primary"
                :rules="[$rules.required]"
                :disabled="loading"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="categoryData.type"
                label="Tipo *" class="ml-1"
                filled
                hide-details="auto"
                :disabled="loading"
                :items="types"
              />
            </v-col>
          </v-row>
          <v-row class="pt-3" no-gutters>
            <v-col cols="6">
              <ColorPicker
                v-model="categoryData.color"
                label="Cor da Conta *"
                inputClass="mr-1"
                :disabled="loading"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="categoryData.parent_id"
                label="Grupo da Conta (opcional)" class="ml-1"
                filled
                hide-details="auto"
                :disabled="loading || loadingCategories"
                :loading="loadingCategories"
                no-data-text="Nenhuma conta cadastrada"
                :items="categories[categoryData.type]"
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>
          <div class="d-flex justify-end mt-3 pb-1">
            <v-btn
              text
              @click="
                newAndEditCategoryDialog = false;
                reset();
              "
            >
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              class="ml-2"
              :loading="loading"
              type="submit"
            >
              Salvar
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ColorPicker from "@/components/ColorPicker.vue"

export default {
  name: "NewAndEditCategoryDialog",
  components: {
    ColorPicker,
  },
  data: () => ({
    selectedFruits: [],
    color: "#1976D2FF",
    newAndEditCategoryDialog: false,
    categories: [],
    loading: false,
    isEditing: false,
    loadingCategories: false,
    category_id: null,
    categoryData: {
      name: undefined,
      color: "blue",
      type: 'inlet',
      cash_flow_id: null,
      parent_id: null,
    },
    types: [
      {text: "Entrada", value: 'inlet'},
      {text: "Saída",   value: 'outflow'},
    ],
  }),
  methods: {
    open(category) {
      if (category) {
        this.isEditing = true;
        this.categoryData = Object.assign({}, category);
        this.category_id = this.categoryData.id;
      } else {
        this.isEditing = false;
      }
      this.newAndEditCategoryDialog = true;
      this.categoryData.cash_flow_id = this.$route.params.cash_flow_id;
      this.loadCategories();
    },
    reset(){
      this.$refs.categoryForm.reset();
      this.$nextTick(() => {
        this.categoryData.type = 'inlet';
      });
    },
    loadCategories() {
      this.loadingCategories = true;
      this.$axios
        .get(`/cash-flow/${this.categoryData.cash_flow_id}/categories/segmented`, {
          params: this.$route.query,
        })
        .then((response) => {
          for (const key in response.data){
            response.data[key].unshift({
              id: null,
              name: "Nenhum",
            });
          }
          this.categories = response.data;
        })
        .catch(() => {
          if (error.response) {
            switch (error.response.status) {
              case 404:
                this.$showMessage("warning", "Controle de Caixa não encontrado");
                return;
            }
          }
          this.$showMessage("error", "Erro inesperado ao carregar as contas");
        })
        .finally(() => {
          this.loadingCategories = false;
        });
    },
    save() {
      if (this.$refs.categoryForm.validate()) {
        this.loading = true;
        let request;
        if (this.isEditing){
          request = this.$axios.put(`/cash-flow/category/${this.category_id}`, this.categoryData);
        } else {
          request = this.$axios.post("/cash-flow/category", this.categoryData);
        }
        request
          .then(() => {
            this.$showMessage("success", "Conta Salva");
            this.newAndEditCategoryDialog = false;
            this.reset();
            this.isEditing = false;
            this.$emit("updated");
          })
          .catch(() => {
            this.$showMessage("error", "Erro inesperado ao salvar Conta");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$showMessage("warning", "Preencha todos os campos corretamente");
      }
    },
  },
};
</script>