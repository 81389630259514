<template>
  <div
    ref="contentDiv"
    :style="{
      overflow: 'auto',
      height: height || 'auto',
    }"
    class="pb-1"
  >
    <div v-for="(categoryList, i) in data" :key="i">
      <div class="d-flex mt-4">
        <div class="text-subtitle-2">
          {{ categoryList.title }}
        </div>
        <v-spacer />
        <v-chip
          v-if="categoryList.total !== undefined"
          small label class="px-2"
        >
          R$ {{$utils.formatMoney(categoryList.total)}}
        </v-chip>
      </div>
      <v-treeview
        class="mt-2"
        :value="$props[`${categoryList.type}_ids`]"
        selected-color="primary"
        :items="trees[categoryList.type]"
        dense
        hoverable
        transition
        disable-per-node
        :selectable="false"
        :height="height"
      >
        <template #label="{ item }">
          <div class="d-flex align-center mb-1">
            <v-checkbox
              v-if="selectable"
              height="2px"
              class="my-0 ml-5"
              dense hide-details
              v-model="item.selected"
              :color="item.color"
              :class="$utils.textColorForBackground(item.color) + '--text'"
              @change="() => {
                $emit('setValue', item);
              }"
            />
            <v-card
              label dark :color="item.color"
              :class="$utils.textColorForBackground(item.color) + '--text'"
              :elevation="0"
              class="py-1 pl-2 pr-1 flex-grow-1 d-flex align-center"
            >
              <div
                class="mr-1 flex-grow-1"
                style="
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-all;
                "
              >
                {{ item.name }}
              </div>
              <div class="d-flex">
                <slot
                  name="append"
                  :category="item"
                />
              </div>
            </v-card>
          </div>
        </template>
      </v-treeview>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriesList",

  props: {
    inlet: {required: true, type: Array},
    outflow: {required: true, type: Array},
    inlet_total: {default: undefined},
    outflow_total: {default: undefined},
    selectable: {type: Boolean, default: false},
  },

  data: () => ({
    height: undefined,
  }),

  computed: {
    trees(){
      return {
        inlet: this.computeTree(this.inlet),
        outflow: this.computeTree(this.outflow),
      };
    },
    data(){
      return [
        {title: "Entradas", type: 'inlet', categories: this.inlet, total: this.inlet_total},
        {title: "Saídas", type: 'outflow', categories: this.outflow, total: this.outflow_total},
      ];
    },
  },

  methods: {
    withChildren(cat){
      if (!cat.children) {
        cat.children = [];
      } else {
        cat.children = cat.children.map(this.withChildren);
      }
      return cat;
    },
    computeTree(cats){
      return cats.map(this.withChildren);
    },
  },

  mounted(){
    this.$nextTick(() => {
      this.height = this.$refs.contentDiv.clientHeight + 'px';
    });
  },
}
</script>
