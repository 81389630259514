<template>
  <v-dialog v-model="searchDialog" max-width="800px">
    <v-card>
      <v-toolbar flat color="grey lighten-2" height="56px">
        <v-toolbar-title>
          Pesquisa de Contas por Complemento
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-2">
        <v-text-field
          v-model="search"
          filled dense
          @click:append-outer="searchRecords"
          label="Pesquisar"
          autofocus
          single-line
          clearable
          @click:clear="cleanValues"
          hide-details
          @keyup.enter="searchRecords"
        />
      </div>

      <v-data-table
        :loading="loadingRecords"
        loading-text="Carregando... Por favor, aguarde"
        :no-data-text="
          search.length > 1
            ? 'Nenhum registro encontrado'
            : 'Digite o complemento que deseja encontrar'"
        :items="records"
        :headers="headers"
        disable-pagination
        hide-default-footer
        fixed-header
        :height="$vuetify.breakpoint.height * 0.9 - 56 * 3"
        :item-class="'v-data-table-inlet'"
      >
        <template #[`item.date`]="{ item }">
          {{
            ''
            + (item.day ? `${item.day < 9 ? '0':''}${item.day}/`:'')
            + (`${item.month < 9 ? '0':''}${item.month + 1}`)
            + `/${item.year}`
          }}
        </template>
        <template #[`item.category`]="{ item }">
        <v-chip
              small label dark :color="item.category.color"
              class="break-span ma-1 px-2 py-1 ml-2"
              :class="$utils.textColorForBackground(item.category.color) + '--text'"
              style="height: auto"
            >
              {{ item.category.name }}
            </v-chip> </template>
        <template #[`item.type`]="{ item }">
          <div
            :class="{
              'error--text': item.type === 'outflow',
              'success--text': item.type === 'inlet',
            }"
            class="py-2 px-1 rounded-r-sm text-center text--darken-2">
            {{ item.type === 'outflow'? 'Saída' : 'Entrada' }}
          </div>
        </template>
        <template #[`item.amount`]="{item }">
          <div>
            R$ {{ $utils.formatMoney( item.amount) }}
          </div>
        </template>
      </v-data-table>
      <div class="pa-2 d-flex grey lighten-2" style="height: 56px">
          <v-row no-gutters v-if="records.length > 0">
            <v-col>
              <v-card class="mx-1 d-flex align-center grey lighten-3" elevation="0">
                <v-row align="center" no-gutters style="width: 50%;">
                  <v-col cols="5">
                    <v-card color="teal darken-3 white--text" class="py-2 px-1 rounded-r-sm text-center">
                      Entradas:
                    </v-card>
                  </v-col>
                  <v-col cols="7" class="px-2">
                    R$ {{ $utils.formatMoney(totalInlet) }}
                  </v-col>
                </v-row>    
                <v-row align="center" no-gutters style="width: 50%;">
                  <v-col cols="5">
                    <v-card
                      color ="deep-orange darken-3 white--text"
                      class="py-2 px-1 rounded-r-sm text-center">
                      Saídas:
                    </v-card>
                  </v-col>
                  <v-col cols="7" class="px-2">
                    R$ {{ $utils.formatMoney(totalOutflow) }}
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SearchDialog",

  data: () => ({
    searchDialog: false,
    search: "",
    loadingRecords: false,
    totalOutflow: 0,
    totalInlet: 0,
    cash_flow_id: null,
    records: [],
    headers: [
      { text: "Tipo", value: "type" },
      { text: "Categoria", value: "category" },
      { text: "Complemento", value: "identification" },
      { text: "Data", value: "date" },
      { text: "Valor", value: "amount" },
    ],
    changeTimeout: undefined,
  }),


  methods: {
    open(cash_flow_id) {
      this.searchDialog = true;
      this.cash_flow_id = cash_flow_id;
    },

    searchRecords() {
      this.loadingRecords = true;
      this.$axios
        .get(`/cash-flow/${this.cash_flow_id}/search`, {
          params: {
            search: this.search,
          },
        })
        .then((response) => {
          this.records = response.data.records;
          this.totalOutflow = response.data.total_outflow;
          this.totalInlet = response.data.total_inlet;
        })
        .catch(() =>
          this.$showMessage("error", "Erro inesperado ao carregar as contas")
        )
        .finally(() => {
          (this.loadingRecords = false);
        });
    },

    cleanValues(){
      this.search = '';
      this.records = [];
      this.totalOutflow = 0;
      this.totalInlet = 0;
    },

    resetLoader(){
      if (this.changeTimeout !== undefined){
        clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = setTimeout(() => {
        if (this.loadingRecords){
          this.resetLoader();
        } else {
          if (this.search.trim().length > 0){
            this.searchRecords();
          }
        }
      }, 300);
    },
  },

  watch: {
    search() {
      if (this.searchDialog){
        if (this.search.trim().length === 0) {
          this.records = [];
        }else{
          this.resetLoader();
        }
      }
    },

    searchDialog(){
      if(!this.searchDialog){
        this.cleanValues();
      }
    }
  },

  destroyed() {
    this.cleanValues();
  }
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(44, 60, 86, 0.08);
}
</style>
