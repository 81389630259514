<template>
  <v-dialog v-model="showing" max-width="600" persistent>
    <v-card>
      <v-toolbar flat dark color="primary" max-height="65px">
        <v-toolbar-title>
          {{
            isEdit ? 
            "Edição do Controle de Caixa":
            "Novo Controle de Caixa"
          }}
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="cashFlowForm" @submit.prevent="save()">
        <v-card-text>
          <v-text-field
            v-model="cashFlow.name"
            label="Nome do Controle *"
            hide-details="auto" filled
            color="primary"
            :rules="[$rules.required]"
            :disabled="loading"
          />
          <v-select
            :items="types"
            v-model="cashFlow.type"
            label="Tipo do Controle *"
            hide-details="auto" filled
            color="primary"
            class="mt-2"
            :rules="[$rules.required]"
            :disabled="loading"
          />
          <v-checkbox
            v-if="!consolidator_id"
            v-model="cashFlow.is_consolidator"
            hide-details="auto"
            label="Controle Consolidador"
            :disabled="loading || isEdit"
          />
        </v-card-text>
        <v-card-actions class="px-4 pb-4 pt-0">
          <v-spacer />
          <v-btn text :disabled="loading" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn
            color="success" class="ml-2"
            :loading="loading" type="submit"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewAndEditCashFlowDialog",

  props: {
    consolidator_id: {type: Number, required: false},
  },

  data: () => ({
    title: undefined,
    showing: false,
    loading: false,
    types: [
      {text: "Mensal", value: 'monthly'},
      {text: "Mensal com Dias", value: 'monthly_with_days'},
      {text: "Diário", value: 'daily'},
    ],
    cashFlow: {},
  }),

  computed: {
    isEdit(){
      return this.cashFlow.id != null;
    },
  },

  methods: {
    cancel(){
      this.showing = false;
      this.$refs.cashFlowForm.reset();
    },
    open(cashFlow){
      if (this.$refs.cashFlowForm){
        this.$refs.cashFlowForm.reset();
      }
      if (cashFlow !== undefined) {
        this.cashFlow = Object.assign({}, cashFlow);
        this.cashFlow.consolidator_id = this.consolidator_id;
      } else {
        this.cashFlow = {
          name: undefined,
          type: 'monthly',
          is_consolidator: false,
          consolidator_id: this.consolidator_id,
        };
      }
      this.showing = true;
    },
    save(){
      if (this.$refs.cashFlowForm.validate()) {
        this.loading = true;
        let request;
        if (this.isEdit){
          request = this.$axios.put(`/cash-flow/${this.cashFlow.id}`, this.cashFlow);
        } else {
          request = this.$axios.post("/cash-flow", this.cashFlow);
        }
        request
          .then((response) => {
            this.$emit("updated");
            this.$showMessage("success", "Controle de Caixa Salvo");
            this.showing = false;
            this.$refs.cashFlowForm.reset();
            if (!this.isEdit){
              this.$router.push({
                name: 'cash-flow',
                params: {
                  'cash_flow_id': response.data
                },
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 404:
                  this.$showMessage(
                    "warning",
                    "Controle de Caixa não encontrado"
                  );
                  return;
              }
            }
            this.$showMessage(
              "error",
              "Erro inesperado ao editar o controle de caixa"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$showMessage("warning", "Preencha todos os campos obrigatórios");
      }
    },
  },
};
</script>